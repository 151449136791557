import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  Inject,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ModalData } from '../modal.model';

@Component({
  selector: 'tax-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass'],
})
export class ModalComponent implements AfterViewInit {
  @ViewChild('container', {
    read: ViewContainerRef,
    // @ts-ignore
  }) container: ViewContainerRef;
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private vcr: ViewContainerRef,
    private elementRef: ElementRef
  ) {
  }

  ngAfterViewInit(): void {
    this.createComponent();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private createComponent(): void {
    const componentRef: ComponentRef<any> = this.vcr.createComponent(this.modalData.component);
    Object.assign(componentRef.instance, this.modalData.componentInputs);
    if (this.modalData.settings.backgroundColor){
      this.elementRef.nativeElement.parentElement.style.backgroundColor = this.modalData.settings.backgroundColor;
    }
    componentRef.changeDetectorRef.detectChanges();
  }
}
