import { Injectable } from '@angular/core';
import { Observable, of, share } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SettingsApiService } from '../modules/settings/settings-api.service';
import { Settings } from '../modules/settings/settings.types';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private observable?: Observable<Settings>;
  private settings?: Settings;

  constructor(
    private settingsApiService: SettingsApiService,
  ) {
  }

  getSettings(): Observable<Settings> {
    if (this.settings) {
      return of(this.settings);
    }
    if (this.observable) {
      return this.observable;
    }
    this.observable = this.settingsApiService.fetchSettings()
      .pipe(
        tap((settings) => {
          this.observable = undefined;
          this.settings = settings;
        }),
        share(),
      );
    return this.observable;
  }
}
