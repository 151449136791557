import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputControlComponent } from '../base-input-control/base-input-control.component';

@Component({
  selector: 'tax-password',
  templateUrl: 'password.component.html',
  styleUrls: ['password.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordComponent,
      multi: true,
    },
  ],
})
export class PasswordComponent extends BaseInputControlComponent {
  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
