import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { mergeMap, Observable } from 'rxjs';
import { successAlert } from '../../shared/components/alerts/alert.decorator';
import { Pages } from '../../system/pages';
import { RetailPlacesApiService } from '../modules/retail-places/retail-places-api.service';
import { Permission } from '../modules/admin/admin.types';
import {
  CityItemModel,
  HouseItemModel,
  RegionItemModel,
  StreetItemModel,
} from '../modules/retail-places/retail-paces.types';

@Injectable()
export class RetailPlaceService {
  constructor(
    private retailPacesApiService: RetailPlacesApiService,
    private router: Router,
  ) {}

  getRegion(region: string): Observable<RegionItemModel[]> {
    const params = new HttpParams().set('region', region);
    return this.retailPacesApiService.fetchRegion(params);
  }

  getToponyms(areaId: string, city: string): Observable<CityItemModel[]> {
    const params = new HttpParams().set('city', city);
    return this.retailPacesApiService.fetchToponyms(areaId, params);
  }

  getGeonims(localityId: string, street: string): Observable<StreetItemModel[]> {
    const params = new HttpParams().set('street', street);
    return this.retailPacesApiService.fetchGeonims(localityId, params);
  }

  getBuildings(geonimId: string, building: string = ''): Observable<HouseItemModel[]> {
    const params = new HttpParams().set('number', building);
    return this.retailPacesApiService.fetchBuildings(geonimId, params);
  }

  setNewTitle(id: number, title: string): Observable<any> {
    const params = new HttpParams().set('newTitle', title);
    return this.retailPacesApiService.setNewRetailPlaceTitle(id, params);
  }

  deleteRetailPlace(id: number): Observable<any> {
    return this.retailPacesApiService.deleteRetailPlace(id)
      .pipe(
        mergeMap(() => this.router.navigate([Pages.Kkms])),
        successAlert({
          message: 'kkms.retail.place.delete.success',
        }),
      );
  }

  isFullRetailPlaceAccess(permissions: Permission[]): boolean {
    return permissions.includes('retail.*');
  }
}
