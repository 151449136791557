<div
  data-qa-block="select"
  class="drop-down-list"
  [class.drop-down-visible]="isDropdownListVisible"
>
  <button
    class="drop-down__button"
    type="button"
    data-qa-button="toggle-list-visibility"
    [style]="style"
    [class.drop-down__disabled]="disabled"
    (click)="updateVisibility()"
  >
    <span
      *ngIf="placeholder && !buttonText"
      class="ui-kit-grey-text"
      data-qa="button-placeholder"
      >{{ placeholder | translate }}</span
    >
    <span data-qa="selected-item">{{ buttonText | translate }}</span>
    <tax-icon
      *ngIf="!manualInput"
      [name]="isDropdownListVisible ? 'triangle-up' : 'triangle-down'"
    ></tax-icon>
    <!--Используем `$event.stopPropagation()` чтобы предотвратить событие клика на кнопке
    `открытия/закрытия` выпадающего списка после того как были зачищены данные ручного ввода по клику на
    иконку.-->
    <tax-icon
      *ngIf="manualInput"
      class="grey-icon"
      name="cancel-circle"
      matTooltipPosition="above"
      [matTooltip]="'common.clean.placeholder' | translate"
      (click)="cleanEvent.emit(); $event.stopPropagation()"
    ></tax-icon>
  </button>
  <tax-icon
    *ngIf="helpText"
    name="help-outline"
    class="help-icon grey-icon"
    matTooltipPosition="above"
    [matTooltip]="helpText | translate"
  ></tax-icon>

  <div class="drop-down-container">
    <div class="drop-down-input">
      <input
        #search
        type="text"
        class="drop-down--input"
        data-qa-input="select-search"
        [placeholder]="placeholder | translate"
        [(ngModel)]="searchText"
        [taxAutofocus]="isDropdownListVisible"
      />
    </div>
    <ng-container *ngIf="data | filter: searchText as filteredData">
      <ul
        class="drop-down-content"
        *ngIf="filteredData.length"
        [class.scrollable]="scrollable"
        data-qa="select-list-items"
      >
        <li
          class="dd-li drop-down-li"
          data-qa="select-item"
          *ngFor="
            let item of filteredData | slice: 0:(isInfinite ? 10000 : 15);
            let i = index;
            trackBy: utilService.trackByFn
          "
          [class.drop-down__selected-item]="item.title === buttonText"
          (click)="selectItem(item)"
        >
          <span class="drop-down__item" translate data-qa="select-item-text">{{ item.title }}</span>
          <span *ngIf="item.child && item.child.length">
            <svg-icon key="arrow-back" class="arrow-right"></svg-icon>
          </span>
          <span *ngIf="item.label" class="label" data-qa="select-item-label">
            {{ item.label | translate }}
          </span>
          <div
            *ngIf="item.child && item.child.length > 0"
            class="drop-down__child"
            [class.dropdown-child-top]="item.child.length <= i || i >= maxItemIndex"
            [class.dropdown-child-bottom]="item.child.length > i && i < maxItemIndex"
          >
            <ul data-qa-block="child-list">
              <li
                class="dd-li"
                *ngFor="let i of item.child"
                (click)="selectItem(i)"
                translate
                data-qa="child-list-item"
              >
                {{ i.title }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <button
        *ngIf="manualInputText && !filteredData.length"
        type="button"
        class="manual-input-button"
        data-qa-button="open-modal"
        (click)="selectManualInput.emit()"
      >
        <tax-icon name="edit-square"></tax-icon>
        <span data-qa="manual-input-text">{{ manualInputText | translate }}</span>
      </button>
    </ng-container>
  </div>
</div>
