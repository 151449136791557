import { Component, OnInit } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from '../../../core/modal/modal.service';
import { AuthApiService } from '../../../core/modules/auth/auth-api.service';

@UntilDestroy()
@Component({
  selector: 'tax-email-confirm',
  templateUrl: './email-confirm.component.html',
  styleUrls: ['./email-confirm.component.sass'],
})

export class EmailConfirmationComponent implements OnInit {
  private isSecretKeyValid$: Subject<boolean | null> = new Subject();
  isSecretKeyValid = (control: AbstractControl): Observable<ValidationErrors | null> => this.isSecretKeyValid$
    .pipe(
      map((isValid: boolean | null) => {
        const error = isValid !== false ? null : {
          errorMessage: 'email.warning.text',
        };
        control.setErrors(error);
        return error;
      }),
    );

  activeWindow: 'emailForm' | 'confirmEmailForm' = 'emailForm';
  userEmail: string = '';
  secretKey = new FormControl<string | null>(null, [], [this.isSecretKeyValid]);

  constructor(
    private modalService: ModalService,
    private authApiService: AuthApiService,
  ) {
  }

  ngOnInit(): void {
    this.cleanErrorStateOnSecretKeyChange();
  }

  back(): void {
    this.secretKey.reset();
    this.activeWindow = 'emailForm';
    this.isSecretKeyValid$.next(null);
  }

  closeModal(email?: string): void {
    this.modalService.close(email);
  }

  sendEmail(): void {
    this.authApiService.sendEmail(this.userEmail)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.activeWindow = 'confirmEmailForm';
        },
      );
  }

  cleanErrorStateOnSecretKeyChange(): void {
    this.secretKey.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.secretKey.setErrors(null);
        this.isSecretKeyValid$.next(null);
      });
  }

  checkSecretKey(): void {
    if (!this.secretKey.value) {
      return;
    }

    this.authApiService.validateSecretKey(this.secretKey.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.closeModal(this.userEmail);
        },
        error: () => {
          this.isSecretKeyValid$.next(false);
        },
      });
  }
}
