import { Injectable } from '@angular/core';
import { Permission } from '../../core/modules/admin/admin.types';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {
  getDisplayedPermissions(): Permission[] {
    return [
      'agent.clients',
      'agent.reports',
      'agent.view',
      'admin.consult',
      'admin.support',
      'billing.integration',
      'cto.integration',
      'default.editContract',
      'default.editKkms',
      'default.editOrgData',
      'default.editUsers',
      'default.getReports',
      'default.viewKkms',
      'default.viewMonitoringFilter',
      'default.viewUsers',
      'user.roam',
    ];
  }

  getDisplayedSelectedPermissions(selectedPermissions: Permission[]): Permission[] {
    const displayedPermissions: Permission[] = this.getDisplayedPermissions();
    return selectedPermissions.filter(value => displayedPermissions.includes(value));
  }
}
