import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { showErrorAlert } from '../../../shared/components/alerts/alert.decorator';
import { LoaderEnabled, SkipPreloader } from '../../services/loader.service';
import { IBp, IForm } from './business-process.types';

@Injectable({
  providedIn: 'root',
})

export class BusinessProcessApiService {
  constructor(private httpClient: HttpClient) {}

  @showErrorAlert()
  @LoaderEnabled() // skipLoader used for decorator
  startProcess(processName: string, data: any, skipLoader = SkipPreloader.false): Observable<IBp> {
    return this.httpClient.post<IBp>(`/api/process/${processName}`, data, {
      params: {
        action: 'start',
      },
    });
  }

  @showErrorAlert()
  @LoaderEnabled() // skipLoader used for decorator
  getFormData<T extends IForm = IForm>(pid: string, tid: string, skipLoader = SkipPreloader.false): Observable<T> {
    return this.httpClient.get<T>(`/api/process/pid/${pid}/tid/${tid}/form`);
  }

  @showErrorAlert()
  @LoaderEnabled()
  submitProcess(pid: string, tid: string, data: any): Observable<IBp> {
    return this.httpClient.post<IBp>(`/api/process/pid/${pid}/tid/${tid}/submit`, data);
  }

  @LoaderEnabled()
  stepBackProcess(pid: string, tid: string, currentFormKey: string, prevFormKey: string): Observable<any> {
    return this.httpClient.post(
      `/api/process/pid/${pid}/tid/${tid}/sendSignal`,
      {
        targetForm: prevFormKey,
      },
      {
        params: {
          signal: currentFormKey,
        },
      },
    );
  }

  @LoaderEnabled()
  sendMessage(pid: string, tid: string, message: string, data: any = {}): Observable<IBp> {
    return this.httpClient.post<IBp>(
      `/api/process/pid/${pid}/tid/${tid}/sendMessage`,
      data,
      {
        params: {
          message,
        },
      },
    );
  }
}
