import { NgModule } from '@angular/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    MatMenuModule,
    SharedModule,
    RouterModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
  ],
  declarations: [
    HeaderComponent,
    LanguageSwitcherComponent,
    SidebarComponent,
  ],
  providers: [],
})
export class LayoutModule {
}
