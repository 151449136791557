import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ValidationService } from '../../shared/components/form-controls/validators/validation.service';
import { AuthApiService } from '../../core/modules/auth/auth-api.service';
import { AlertsService } from '../../core/services/alerts.service';

@UntilDestroy()
@Component({
  selector: 'tax-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.sass'],
})
export class NewPasswordComponent implements OnInit {
  password = new FormControl<string>(
    '',
    {
      nonNullable: true,
      validators: [Validators.required, this.validationService.isPasswordStrength.bind(this)],
    },
  );

  confirmation = new FormControl<string>(
    '',
    {
      nonNullable: true,
      validators: [Validators.required, this.validationService.isEqual.bind(this, this.password)],
    },
  );

  private routeParams: string = '';
  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private authApiService: AuthApiService,
    private validationService: ValidationService,
    private alertsService: AlertsService,
  ) {}

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.paramMap.get('params') || '';
    this.cookieService.delete('PLAY_SESSION');
    this.runIsEqualValidatorOnPasswordChange();
  }

  submit(): void {
    this.authApiService.sendNewPassword(this.password.value, this.routeParams)
      .pipe(
        mergeMap(userData => this.authApiService.singIn({
          login: userData.login,
          secret: this.password.value,
          rememberMe: false,
        })),
        untilDestroyed(this),
      )
      .subscribe(() => this.navigateToKkmsPage());
  }

  private navigateToKkmsPage(): void {
    this.router.navigate(['main/kkms'])
      .then(() => this.alertsService.add({
        message: 'user.new-password.succeeded',
        type: 'success',
      }));
  }

  private runIsEqualValidatorOnPasswordChange(): void {
    this.password.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.confirmation.updateValueAndValidity());
  }
}
