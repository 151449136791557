<div
  data-qa-block="alert"
  class="snackbar {{ alert?.type }}"
  *ngFor="let alert of alerts; let i = index"
>
  <tax-icon class="alert-icon" [name]="alert?.type === 'success' ? 'done' : 'error'"></tax-icon>
  <div class="content">
    <div *ngIf="alert?.title" class="title {{ alert?.type }}" data-qa="alert-title" translate>
      {{ alert?.title }}
    </div>
    <ng-container *ngIf="alert.message.length > 0">
      <div *ngFor="let str of alert.message" data-qa="alert-message">
        {{ str | translate: alert.messageVariables }}
      </div>
    </ng-container>
  </div>
  <tax-icon
    data-qa-button="close-alert"
    class="close-icon"
    name="cancel"
    (click)="close(i)"
  ></tax-icon>
</div>
