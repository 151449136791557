import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NewPasswordComponent } from './system/new-password/new-password.component';
import { AuthGuard } from './core/guadrs/auth.guard';
import { developPages } from './dev-pages/dev.routes';

const routes: Routes = [
  {
    path: 'new-password/:params', component: NewPasswordComponent,
  },
  {
    path: '', redirectTo: 'landing', pathMatch: 'full',
  },
  {
    path: 'landing', loadChildren: () => import('./system/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: () => import('./wrapper.module').then(m => m.WrapperModule),
  },
  ...developPages,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
