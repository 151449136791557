import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[taxNumbersOnly]',
})
export class NumbersOnlyDirective {
  private readonly ALLOWED_KEYS: string[] = ['Backspace', 'Delete', 'Enter', 'Escape', 'Home', 'End', 'ArrowLeft', 'ArrowRight'];
  private readonly ALLOWED_CTRL_KEYS: string[] = ['KeyA', 'KeyC', 'KeyX', 'KeyV'];
  private readonly ALLOWED_ONLY_NUMBERS: RegExp = /^\d+$/;

  @HostListener('paste', ['$event']) onCtrlV(event: ClipboardEvent): void {
    const pastedValue: string = (event.clipboardData || (window as any).clipboardData).getData('text');
    const isNumber: boolean = pastedValue === pastedValue.replace(/\D/g, '');

    if (!isNumber) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    if (
      // Allow numbers
      this.ALLOWED_ONLY_NUMBERS.test(event.key)
      // Allow keys: backspace, delete, enter, escape, home, end, left, right
      || this.ALLOWED_KEYS.includes(event.key)
      // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
      || (this.ALLOWED_CTRL_KEYS.includes(event.code) && (event.ctrlKey || event.metaKey))) {
      // let it happen, don't do anything
      return;
    }
    event.preventDefault();
  }
}
