import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment/moment';

@Injectable()

export class DatepickerDateRangeValidationService {
  private isValidRange$ = new BehaviorSubject<boolean>(true);

  getIsValidRange(): Observable<boolean> {
    return this.isValidRange$.asObservable();
  }

  setIsValidRange(startDate: Moment | null, endDate: Moment | null): void {
    const isValidRange = startDate && endDate
      ? (moment(endDate) >= moment(startDate).startOf('day'))
      : true;
    this.isValidRange$.next(isValidRange);
  }
}
