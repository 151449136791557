import { ConfirmBySmsComponent } from '../../shared/components/modals/confirm-by-sms/confirm-by-sms.component';

export interface ModalData {
  component: any;
  componentInputs: ComponentInputs;
  settings: ModalSettings;
}

export interface ComponentInputs {
  [key:string]: unknown;
}

export interface ModalSettings {
  title?: string;
  closable?: boolean;
  width?: ModalWidth | string;
  backgroundColor?: string;
}

export enum ModalWidth {
  sm = '408px',
  md = '800px',
}

export type ModalComponentType = typeof ConfirmBySmsComponent | unknown;

// TODO: после того, как все использования this.modalService.close будет сделано с указанием типа, поменять дефолтное значение 'unknown' на 'void'
export type ModalOutput<T> =
  T extends typeof ConfirmBySmsComponent ? string | null : unknown;
