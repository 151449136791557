import { AfterViewInit, Component, forwardRef, inject, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { ValidationService } from '../validators/validation.service';
import { BaseInputControlComponent } from '../base-input-control/base-input-control.component';
import { UtilsService } from '../../../../core/services/utils.service';

@Component({
  selector: 'tax-phone-input',
  templateUrl: 'phone-input.component.html',
  styleUrls: ['phone-input.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneInputComponent,
      multi: true,
    },
  ],
})
export class PhoneInputComponent extends BaseInputControlComponent implements AfterViewInit {
  @Input() allowKzPhoneOnly = false;
  mask = '+0 000 000-00-00';
  private utilsService = inject(UtilsService);
  private validationService: ValidationService = inject(ValidationService);

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.addValidators();
    this.controlValueChanges();
  }

  override writeValue(value: any): void {
    const normalizedPhone = value ? this.utilsService.normalizePhone(value) : null;
    this.inputControl.setValue(normalizedPhone);
  }

  private addValidators(): void {
    this.ngControl?.control?.addValidators(this.validationService.phoneValidator());
    if (this.allowKzPhoneOnly) {
      this.ngControl?.control?.addValidators(this.validationService.kzPhoneValidator());
    }
  }

  private controlValueChanges(): void {
    this.ngControl?.control?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.ngControl?.control?.patchValue(value, {
          onlySelf: true, emitEvent: false,
        });
      });
  }
}
