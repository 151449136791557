<div class="columns-field_{{ layoutColumns }}" *ngIf="inputControl">
  <label
    *ngIf="labelText"
    data-qa="label-text"
    [attr.for]="id"
    [class.required-marker]="isRequired"
  >
    {{ labelText | translate }}
  </label>
  <ng-container *ngTemplateOutlet="topActionButton"></ng-container>

  <div
    class="input-wrapper"
    [class.input-wrapper_prefix]="prefix"
    [class.input-wrapper_suffix]="clearable || showSuffixButton"
    [style.width]="width"
  >
    <ng-content></ng-content>

    <tax-icon
      *ngIf="clearable && inputControl.value && !disabled"
      name="cancel-circle"
      class="suffix-button"
      (click)="cleanInput()"
      [matTooltip]="'common.clean.placeholder' | translate"
      matTooltipPosition="above"
    ></tax-icon>
    <svg-icon
      *ngIf="helpText"
      key="help-outline"
      class="help-icon grey-icon"
      [matTooltip]="helpText | translate"
      matTooltipPosition="above"
    ></svg-icon>

    <ng-container *ngTemplateOutlet="inputActionButton"></ng-container>
  </div>

  <div
    *ngIf="errorType === 'bottom' && showError"
    class="ui-kit-error-text"
    [class.right-side]="layoutColumns === 2"
    data-qa="error-text"
  >
    {{ getError() | translate }}
  </div>

  <div *ngIf="descriptionText" class="field-description" [class.right-side]="layoutColumns === 2">
    <span data-qa="description-text">{{ descriptionText | translate }}</span>
    <div *ngIf="descriptionRightSide" class="description__right-side">
      <ng-container *ngTemplateOutlet="descriptionRightSide"></ng-container>
    </div>
  </div>
</div>

<div
  *ngIf="errorType === 'tooltip' && showError"
  class="tooltips-text"
  [class.visible]="isShowTooltipError"
  data-qa="error-text"
>
  {{ getError() | translate }}
</div>
