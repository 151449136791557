import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderEnabled } from '../../services/loader.service';
import { BlockUserBody, SaveUserBody, SessionUser, User } from './users.types';
import { SmsCodeSendResponse } from '../auth/auth.types';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(private httpClient: HttpClient) {}
  // Данные в свагере не полные или не соответствуют правде
  @LoaderEnabled()
  fetchUsers(params: HttpParams): Observable<User[]> {
    return this.httpClient.get<User[]>('/api/administration/filter-users', {
      params,
    });
  }

  // Данные в свагере не полные или не соответствуют правде
  @LoaderEnabled()
  fetchUser(id: number): Observable<User> {
    return this.httpClient.get<User>(`/api/administration/users/${id}`);
  }

  // Данные в свагере не полные или не соответствуют правде
  @LoaderEnabled()
  editUser(user: SaveUserBody): Observable<void> {
    return this.httpClient.post<void>('/api/administration/save-user', user);
  }

  // Данные в свагере не полные или не соответствуют правде
  @LoaderEnabled()
  blockUser(name: string): Observable<BlockUserBody> {
    return this.httpClient.post<BlockUserBody>((`/api/administration/block-user?login=${name}`), {
      login: name,
    });
  }

  fetchCurrentUserInfo(): Observable<SessionUser> {
    return this.httpClient.get<SessionUser>('/api/user/authuser');
  }

  changeUserPhone(newPhone: string, code: string, userId: number): Observable<SmsCodeSendResponse | void> {
    return this.httpClient.post<SmsCodeSendResponse | void>(('/api/administration/update-user-phone'), {
      newPhone, code, userId,
    });
  }

  sendSmsCodeToAuthorizedUser(): Observable<string> {
    return this.httpClient.post<string>(('/api/user/sms/send'), {});
  }
}
