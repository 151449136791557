<mat-form-field
  taxIsElementVisible
  [style]="styles"
  (visibilityChanged)="setElementVisibility($event)"
  [class.error]="(isValidDate$ | async) === false || (isValidRange$ | async) === false"
>
  <input
    matInput
    taxDatepickerInput
    [(ngModel)]="date"
    [max]="maxDate || null"
    [min]="minDate || null"
    [attr.tabindex]="tabindex || null"
    [matDatepicker]="picker"
    [taxAutofocus]="autofocus || false"
    [placeholder]="label || '' | translate"
    [startDate]="_startDate"
    (dateInput)="inputEvent.emit($event)"
  />
  <mat-datepicker-toggle
    *ngIf="tooltip"
    matSuffix
    [for]="picker"
    nz-tooltip
    [nzTooltipTitle]="tooltipTemplate"
    [nzTooltipVisible]="isTooltipVisible && isElementVisible"
  ></mat-datepicker-toggle>
  <mat-datepicker-toggle *ngIf="!tooltip" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-label *ngIf="label" translate data-qa="label">{{ label }}</mat-label>
  <mat-datepicker #picker></mat-datepicker>
  <div class="datepicker-error-text">
    <span *ngIf="(isValidDate$ | async) === false">{{
      'form.error.date.incorrect' | translate
    }}</span>
    <span *ngIf="(isValidRange$ | async) === false && (isValidDate$ | async) === true">{{
      'form.error.date.range.incorrect' | translate
    }}</span>
  </div>
</mat-form-field>

<ng-template #tooltipTemplate>
  <ng-container *ngTemplateOutlet="tooltip"></ng-container>
</ng-template>
