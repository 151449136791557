import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService, SupportedLanguages } from '../../services/language.service';

@Component({
  selector: 'tax-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.sass'],
})
export class LanguageSwitcherComponent {
  currentLanguage: SupportedLanguages = this.languageService.currentLanguage.language;

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
  ) {
  }

  setLanguage(language: SupportedLanguages): void {
    this.currentLanguage = language;
    this.languageService.changeLanguage(language);
  }
}
