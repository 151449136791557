import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from './modal/modal.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ModalContentComponent } from './modal-content/modal-content.component';

const exportedComponents = [
  ModalHeaderComponent,
  ModalContentComponent,
  ModalFooterComponent,
];

@NgModule({
  imports: [
    MatDialogModule,
    CommonModule,
    TranslateModule,
  ],
  exports: [
    ...exportedComponents,
  ],
  declarations: [
    ModalComponent,
    ...exportedComponents,
  ],
  providers: [
  ],
})
export class ModalModule {
}
