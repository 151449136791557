import { Component, Input, OnDestroy } from '@angular/core';
import { ErrorService } from '../../../../core/services/error.service';
import { ModalService } from '../../../../core/modal/modal.service';

@Component({
  selector: 'tax-certificate-modal-error',
  templateUrl: './certificate-modal-error.component.html',
  styleUrls: ['./certificate-modal-error.component.sass'],
})
export class CertificateErrorModalComponent implements OnDestroy {
  // @ts-ignore
  @Input() message: string;

  constructor(
    private modalService: ModalService,
    private errorService: ErrorService,
  ) {}

  ngOnDestroy(): void {
    this.errorService.clearError();
  }

  closeModal(): void {
    this.modalService.close();
  }
}
