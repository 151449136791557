<tax-page-header titleText="notifications.page"></tax-page-header>

<div class="filters-box">
  <!--  <div class="search-input">
    <tax-input [(ngModel)]="searchField" placeholder="РНМ, серийный номер" [autofocus]="true" [clearable]="true"></tax-input>
    <button class="button_blue" (click)="onSearch()" translate>address.modal.search</button>
  </div>-->

  <div class="date-filters">
    <tax-datepicker
      [(ngModel)]="dateFrom"
      (inputEvent)="inputDate($event, 'dateFrom')"
    ></tax-datepicker>
    <tax-datepicker
      [(ngModel)]="dateTo"
      [startDate]="dateFrom"
      (inputEvent)="inputDate($event, 'dateTo')"
    ></tax-datepicker>
    <button
      class="button_link"
      [disabled]="isShowDisabled"
      (click)="getFilterNotifications()"
      translate
    >
      button.show
    </button>

    <div class="ui-kit-tag-list">
      <div
        class="ui-kit-tag"
        *ngFor="let tag of tags | keyvalue: objectInOriginalOrder"
        [class.tag__selected]="tag.value.selected"
        (click)="selectTag(tag)"
      >
        <span translate>{{ tag.value.name }}</span>
      </div>
    </div>
  </div>
</div>

<div class="action-box" *ngIf="hasUnreadNotifications">
  <label translate>
    <input type="checkbox" (change)="selectAll()" [(ngModel)]="isAllSelected" />
    group.modal.retail.place.selectall
  </label>
  <button class="button_link" (click)="changeReadStatus()" [disabled]="!isSomeSelected()" translate>
    mark.as.read
  </button>
</div>

<div class="notification-box">
  <div
    class="item"
    [class.broadcast]="n.notificationType === 'BROADCAST_NOTIFICATION'"
    [class.select-active]="!n.isRead && n.selectedActive"
    *ngFor="let n of notifications"
  >
    <input
      type="checkbox"
      [(ngModel)]="n.selected"
      (change)="setSelectAll()"
      [disabled]="n.isRead"
    />
    <div class="info">
      <span
        [class.is-unread]="!n.isRead"
        [class.warning]="n.level === 'WARNING' && !n.isRead"
        matTooltip="{{ n.isRead ? '' : ('mark.as.read' | translate) }}"
        (click)="n.isRead ? null : changeReadStatus(n)"
      ></span>
      <div>
        <div class="title">
          <b [class.warning]="n.level === 'WARNING'">{{ n.shortTitle }}</b>
          {{ n.dateTime | date: 'dd.MM.yy' }}
        </div>
        <pre>{{ n.body }}</pre>
      </div>
    </div>
  </div>
  <div *ngIf="isNoNotifications" translate>notifications.no.events</div>
</div>
