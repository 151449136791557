import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { AuthGuard } from './guadrs/auth.guard';
import { SharedModule } from '../shared/shared.module';
import { ErrorService } from './services/error.service';
import { RetailPlaceService } from './services/retail-place.service';

@NgModule({
  declarations: [
  ],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    MatMenuModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [
    AuthGuard,
    ErrorService,
    RetailPlaceService,
  ],
  exports: [
  ],
})

export class CoreModule {}
