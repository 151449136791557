<tax-form-control-wrapper
  *ngIf="inputControl && ngControl"
  [clearable]="clearable"
  [disabled]="disabled"
  [descriptionText]="descriptionText"
  [descriptionRightSide]="descriptionRightSide"
  [errorType]="errorType"
  [errorText]="errorText"
  [helpText]="helpText"
  [id]="id"
  [inputActionButton]="inputActionButton"
  [inputControl]="inputControl"
  [isShowTooltipError]="isShowTooltipError"
  [labelText]="labelText || 'organization.phone'"
  [layoutColumns]="layoutColumns"
  [ngControl]="ngControl"
  [topActionButton]="topActionButton"
  [width]="width"
  (cleanEvent)="cleanEvent.emit()"
>
  <input
    [id]="id"
    [formControl]="inputControl"
    [attr.disabled]="disabled || null"
    [class.empty]="!inputControl.value || disabled"
    [attr.placeholder]="placeholder ? (placeholder | translate) : null"
    [style]="inputStyles || null"
    [style.width]="width"
    type="text"
    maxlength="16"
    [mask]="mask || null"
    [dropSpecialCharacters]="false"
    [taxAutofocus]="autofocus"
    [tabindex]="tabindex"
    (mouseover)="showTooltipError()"
    (mouseout)="hideTooltipError()"
    data-qa="input"
  />
</tax-form-control-wrapper>
