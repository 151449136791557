import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { REGEXP_EXCLUDE_DIGITS } from './regexp';

@Injectable({
  providedIn: 'root',
})

export class UtilsService {
  INPUT_DEBOUNCE_TIME = 1000;

  public generatePassword(): string {
    const PASSWORD_LENGTH: number = 8;
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const numeric = '0123456789';
    const punctuation = '!@#$%^&*-_=+\\|:;,.<>/?~';
    let password = '';
    let character = '';
    while (password.length < PASSWORD_LENGTH) {
      const entity1 = Math.ceil(letters.length * Math.random() * Math.random());
      const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      const entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
      let hold = letters.charAt(entity1);
      hold = (password.length % 2 === 0) ? (hold.toUpperCase()) : (hold);
      character += hold;
      character += numeric.charAt(entity2);
      character += punctuation.charAt(entity3);
      password = character;
    }
    password = password.split('')
      .sort(() => (0.5 - Math.random()))
      .join('');
    return password.substr(0, PASSWORD_LENGTH);
  }

  objectInOriginalOrder = (a: any, b: any) => a.key;

  // TODO: delete when all components will be use 'trackById'
  public trackByFn(index: number, item: unknown): number {
    // @ts-ignore
    return item.id;
  }

  public trackById(this: void, index: number, item: unknown): number {
    // @ts-ignore
    return item?.id;
  }

  trackByIndex(this: void, index: number, item: unknown): number {
    return index;
  }

  cloneDeep<T>(array: T[]): T[] {
    return JSON.parse(JSON.stringify(array));
  }

  getHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(params)
      .forEach((key) => {
        if (![null, undefined].includes(params[key])) {
          httpParams = httpParams.append(key, String(params[key]));
        }
      });
    return httpParams;
  }

  /**
   * @returns Телефон в формате +X XXX XXX-XX-XX, если введены все 11 цифр номера телефона
   */
  normalizePhone(phone: string): string {
    const phoneSections = phone
      .replace(REGEXP_EXCLUDE_DIGITS, '')
      .substring(0, 11)
      .match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

    return phoneSections
      ? `+${phoneSections[1]} ${phoneSections[2]} ${phoneSections[3]}-${phoneSections[4]}-${phoneSections[5]}`
      : phone;
  }

  sortBy<T extends any[]>(array: T, property: string, direction: 'ASC' | 'DESC' = 'ASC'): T {
    if (direction === 'ASC') {
      return array.sort((a, b) => (a[property] > b[property] ? 1 : -1));
    }
    return array.sort((a, b) => (b[property] > a[property] ? 1 : -1));
  }
}
