import {
  Directive,
  EventEmitter,
  HostListener,
  Output,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[taxClickOutside]',
})
export class ClickOutsideDirective {
  @Output() taxClickOutside = new EventEmitter<void>();

  constructor(private viewContainerRef: ViewContainerRef) {}
  @HostListener('document:click', ['$event']) onDocumentClick(event: PointerEvent): void {
    const clickedInside: boolean = event.composedPath()
      .some(element => element === this.viewContainerRef.element.nativeElement);
    if (!clickedInside) {
      this.taxClickOutside.emit();
    }
  }
}
