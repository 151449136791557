import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ModulesPermissionsModel } from './admin.types';

@Injectable({
  providedIn: 'root',
})

export class AdminApiService {
  constructor(private httpClient: HttpClient) {}

  fetchModules(): Observable<ModulesPermissionsModel[]> {
    return this.httpClient.get<ModulesPermissionsModel[]>('/api/administration/modules');
  }
}
