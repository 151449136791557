import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Injector,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { ErrorType } from '../form.model';

@UntilDestroy()
@Component({
  selector: 'tax-base-input-control',
  templateUrl: './base-input-control.component.html',
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseInputControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: BaseInputControlComponent,
      multi: true,
    },
  ],
})
export class BaseInputControlComponent implements ControlValueAccessor, AfterViewInit {
  // w3c input attributes
  @Input() autocomplete = true;
  @Input() autofocus = false;
  @Input() disabled = false;
  @Input() placeholder?: string;

  @Input() required = false;
  @Input() size?: number; // ??
  @Input() width?: string; // ??

  // w3c global attributes
  @Input() id = String(Math.floor(Math.random() * Date.now()));
  @Input() tabindex?: number;

  // component's inputs
  // Используется для template driven forms
  @Input() asyncValidators: AsyncValidatorFn[] = [];
  @Input() clearable = false;
  @Input() descriptionText?: string;
  @Input() descriptionRightSide?: TemplateRef<any>;
  @Input() errorType: ErrorType = 'bottom';
  // Используется для шаблонных форм и ошибок, пришедших извне (с сервера, например)
  @Input() errorText?: string;
  @Input() helpText?: string;
  @Input() inputActionButton: TemplateRef<any> | null = null;
  @Input() inputStyles?: string; // ??
  @Input() labelText?: string;
  @Input() layoutColumns: 1 | 2 = 1;
  @Input() mask?: string;
  @Input() minlength?: number;
  @Input() maxlength?: number;
  @Input() name?: string;
  @Input() readonly: boolean = false;
  @Input() pattern: string = '';
  @Input() patternErrorText?: string;
  @Input() topActionButton: TemplateRef<any> | null = null;
  // Используется для template driven forms
  @Input() validators: ValidatorFn[] = [];

  @Output() cleanEvent = new EventEmitter<void>();

  /**
   * Если не проинициализировать контрол сразу, то придётся юзать setInterval в методах setDisabledState и writeValue.
   * Возможно ещё где-то. В общем появляется баг: не отображается начальное состояние контрола в template (disable, value, ...).
   */
  inputControl = new FormControl<string | null>('');
  isDisabled = false;
  isPasswordVisible = false;
  isShowTooltipError = false;
  ngControl?: NgControl;
  validateEvent$ = new Subject<unknown>();

  private cdr = inject(ChangeDetectorRef);
  private injector = inject(Injector);

  ngAfterViewInit(): void {
    this.ngControl = this.injector.get(NgControl);
    this.inputControl.addValidators(this.getValidators());
    this.inputControl.addAsyncValidators(this.getAsyncValidators());
    this.cdr.detectChanges();
    this.updateControlValue();
  }

  hideTooltipError(): void {
    this.isShowTooltipError = false;
    this.cdr.detectChanges();
  }

  showTooltipError(): void {
    this.isShowTooltipError = true;
    this.cdr.detectChanges();
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.inputControl.setValue(value);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    /**
     * Посылаем дату, т.к. если ничего не посылать, то сеттер в FormControlWrapperComponent не срабатывает после первого события
     */
    this.validateEvent$.next(new Date());

    if (control.touched) {
      this.inputControl?.markAsTouched();
    }

    /**
     * Т.к. маска ввода работает с внутренним контролом inputControl, то нам надо для внешнего контрола ngControl вручную указать ошибку или запустить валидатор.
     * Используем queueMicrotask для того, чтобы не было мельканий состояния enabled/disabled кнопки отправки данных
     */
    queueMicrotask(() => {
      if (this.inputControl?.errors?.mask?.requiredMask) {
        control.setErrors({
          mask: this.inputControl.errors.mask.requiredMask,
        });
      }
    });

    return null;
  }

  // Используется для template driven forms
  getValidators(): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    validators.push(...this.validators);
    if (this.required) {
      validators.push(Validators.required);
    }

    return validators;
  }

  // Используется для template driven forms
  getAsyncValidators(): AsyncValidatorFn[] {
    const asyncValidators: AsyncValidatorFn[] = [];
    asyncValidators.push(...this.asyncValidators);
    return asyncValidators;
  }

  // Используется для реактивных форм
  updateControlValue(): void {
    this.inputControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((password) => {
        this.onChange(password);
      });
  }
}
