import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';

@Component({
  selector: 'tax-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass'],
})
export class LoaderComponent implements OnInit {
  @Input() isMainContent: boolean = false;

  constructor(public loaderService: LoaderService) { }

  ngOnInit() {
  }

  get loaderEnabled() {
    return this.loaderService.loaderEnabled;
  }
}
