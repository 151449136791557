import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { merge, Observable, Subject, startWith, switchMap } from 'rxjs';
import { NotificationApiService } from '../modules/notification/notification-api.service';
import { INotificationsData } from '../modules/notification/notification.types';
import { PaymentAccountApiService } from '../modules/payment-account/payment-account-api.service';
import { IInvoices, IInvoiceTariffs, IReversRolls } from '../modules/payment-account/payment-account.types';

export interface INotification {
  id: number;
  dateTime: number;
  title: string;
  shortTitle: string;
  body: string;
  level: 'INFO' | 'WARNING' | 'DANGER';
  notificationType: 'BROADCAST_NOTIFICATION' | 'PRIVATE_NOTIFICATION';
  isRead: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  readonly reloadNotificationsEvent$ = new Subject<void>();
  constructor(
    private paymentAccountApiService: PaymentAccountApiService,
    private notificationApiService: NotificationApiService,
  ) {}

  getNotificationsData(): Observable<INotificationsData> {
    return merge(this.reloadNotificationsEvent$.pipe(startWith(null)))
      .pipe(switchMap(() => this.notificationApiService.fetchNotifications()));
  }

  public getInvoices(endDate: Date, startDate: Date): Observable<IInvoices> {
    const params = new HttpParams()
      .set('endDate', new Date(endDate).getTime().toString())
      .set('startDate', new Date(startDate).getTime().toString());
    return this.paymentAccountApiService.fetchInvoices(params);
  }

  getReverseRoll(endDate: Date, startDate: Date): Observable<IReversRolls> {
    const params = new HttpParams()
      .set('endDate', new Date(endDate).getTime().toString())
      .set('startDate', new Date(startDate).getTime().toString());
    return this.paymentAccountApiService.fetchReverseRoll(params);
  }

  getInvoice(id: number, date: string): Observable<IInvoiceTariffs> {
    const params = new HttpParams().set('invoiceDate', date);
    return this.paymentAccountApiService.fetchInvoice(id, params);
  }
}
