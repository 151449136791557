import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { InputComponent } from './input/input.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { PasswordComponent } from './password/password.component';
import { EmailValidatorDirective } from './validators/email-validator.directive';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { IconsModule } from '../../icon/icons.module';
import { BaseInputControlComponent } from './base-input-control/base-input-control.component';
import { FormControlWrapperComponent } from './form-control-wrapper/form-control-wrapper.component';
import { isHaveNameAndSurnameDirective } from './validators/name-validator.directive';

const controls = [
  BaseInputControlComponent,
  FormControlWrapperComponent,
  InputComponent,
  CheckboxComponent,
  PhoneInputComponent,
  PasswordComponent,
];

const directives = [
  PhoneMaskDirective,
  NumbersOnlyDirective,
  EmailValidatorDirective,
  isHaveNameAndSurnameDirective,
  AutofocusDirective,
];

@NgModule({
  imports: [
    TranslateModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    IconsModule,
    MatTooltipModule,
    NgxMaskDirective,
  ],
  exports: [
    ...controls,
    ...directives,
  ],
  declarations: [
    ...controls,
    ...directives,
  ],
  providers: [
    provideNgxMask(),
  ],
})
export class FormModule {
}
