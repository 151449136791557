import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderEnabled } from '../../services/loader.service';
import { BankData, IOrganisation, OrganisationForm, PersonWorkPosition } from './organization.types';

@Injectable({
  providedIn: 'root',
})

export class OrganizationApiService {
  constructor(private httpClient: HttpClient) {}

  @LoaderEnabled()
  fetchOrganization(): Observable<IOrganisation> {
    return this.httpClient.get<IOrganisation>('/api/organisation');
  }

  @LoaderEnabled()
  fetchPositions(): Observable<PersonWorkPosition[]> {
    return this.httpClient.get<PersonWorkPosition[]>('/api/positions');
  }

  @LoaderEnabled()
  fetchOrganisationTypes(): Observable<OrganisationForm[]> {
    return this.httpClient.get<OrganisationForm[]>('/api/organisation/organisationForms');
  }

  @LoaderEnabled()
  fetchBanks(): Observable<BankData[]> {
    return this.httpClient.get<BankData[]>('/api/banks');
  }
}
