<tax-form-control-wrapper
  *ngIf="inputControl"
  [clearable]="clearable"
  [disabled]="disabled"
  [descriptionText]="descriptionText"
  [descriptionRightSide]="descriptionRightSide"
  [errorType]="errorType"
  [errorText]="errorText"
  [patternErrorText]="patternErrorText"
  [helpText]="helpText"
  [id]="id"
  [inputActionButton]="inputActionButton"
  [inputControl]="inputControl"
  [isShowTooltipError]="isShowTooltipError"
  [labelText]="labelText"
  [layoutColumns]="layoutColumns"
  [ngControl]="ngControl"
  [prefix]="prefix"
  [topActionButton]="topActionButton"
  [width]="width"
  [validateEvent]="validateEvent$ | async"
  (cleanEvent)="cleanEvent.emit()"
>
  <input
    [id]="id"
    [formControl]="inputControl"
    [attr.disabled]="disabled || null"
    [attr.autocomplete]="autocomplete || 'off'"
    [class.empty]="clearable && (!inputControl.value || disabled)"
    [class.disabled]="disabled"
    [attr.placeholder]="placeholder ? (placeholder | translate) : null"
    [attr.size]="size || null"
    [style]="inputStyles || null"
    [taxAutofocus]="autofocus && !disabled"
    [mask]="mask || null"
    [tabindex]="tabindex"
    [minlength]="minlength || null"
    [maxlength]="maxlength || null"
    [readonly]="readonly"
    [pattern]="pattern || ''"
    (mouseover)="showTooltipError()"
    (mouseout)="hideTooltipError()"
    data-qa="input"
  />
</tax-form-control-wrapper>
