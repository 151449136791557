import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private coockieService: CookieService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<any> {
    if (this.coockieService.get('PLAY_SESSION')) {
      const authReq = req.clone({
        headers: req.headers.set('X-XSRF-TOKEN', this.coockieService.get('PLAY_SESSION')),
      });

      return next.handle(authReq).pipe(tap());
    } return next.handle(req);
  }
}
