import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, timer, map, take, tap } from 'rxjs';
import { showErrorAlert } from '../../alerts/alert.decorator';
import { AuthApiService } from '../../../../core/modules/auth/auth-api.service';
import { LoginBySmsFormValue, SmsCodeSendResponse, SmsSendNewPhone } from '../../../../core/modules/auth/auth.types';

@Injectable({
  providedIn: 'root',
})
export class ConfirmBySmsService {
  MAX_ATTEMPTS_COUNT: number = 3;
  private INITIAL_TIME: number = 5 * 60;

  attemptCount$: Subject<number> = new ReplaySubject(1);
  countdown$: Observable<string> = timer(0, 1000)
    .pipe(
      take(this.INITIAL_TIME + 1),
      map((value) => {
        if (value === 0) {
        // TODO: try use defer instead this
          this.attemptCount$.next(--this.attemptCount);
        }
        return this.INITIAL_TIME - value;
      }),
      map(this.timeFormat),
    );

  private attemptCount = this.MAX_ATTEMPTS_COUNT;

  constructor(private authApiService: AuthApiService) {
    this.attemptCount$.next(this.MAX_ATTEMPTS_COUNT);
  }

  sendPasswordToNewPhone(payload: SmsSendNewPhone): Observable<SmsCodeSendResponse> {
    return this.authApiService.sendPasswordToNewPhone(payload);
  }

  @showErrorAlert()
  sendPasswordToUserPhone(payload: LoginBySmsFormValue): Observable<SmsCodeSendResponse> {
    return this.authApiService.sendPasswordToUserPhone(payload).pipe(
      tap((result) => {
        if (!result.success) {
          return;
        }

        if (result.params?.resendCount) {
          this.attemptCount$.next(result.params.resendCount);
        }
      }),
    );
  }

  private timeFormat(seconds: number): string {
    const secondsText: number = seconds % 60;
    const secondsPrefix: string = secondsText < 10 ? '0' : '';
    return `${Math.floor(seconds / 60)}:${secondsPrefix}${secondsText}`;
  }
}
