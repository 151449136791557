import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UsersService } from '../../../system/users/user.service';
import { AuthorizedUserInfo } from '../../../system/users/users-page/users-page.types';
import { KkmsApiService } from '../../modules/kkms/kkms-api.service';
import { AuthApiService } from '../../modules/auth/auth-api.service';
import { INotificationsData } from '../../modules/notification/notification.types';
import { OrganizationApiService } from '../../modules/organization/organization-api.service';
import { IOrganisation } from '../../modules/organization/organization.types';
import { PaymentAccountApiService } from '../../modules/payment-account/payment-account-api.service';
import { IBalance } from '../../modules/payment-account/payment-account.types';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'tax-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @Input() isKnownUser: boolean;

  // @ts-ignore
  userInfo$: Observable<AuthorizedUserInfo>;
  // @ts-ignore
  totalCount: ITotalCount;
  // @ts-ignore
  organization: IOrganisation;
  // @ts-ignore
  balance: IBalance;
  private subscription: Subscription[] = [];
  notificationsData: INotificationsData = {
    notifications: [],
    totalCount: 0,
    unreadCount: 0,
  };

  constructor(
    private kkmsApiService: KkmsApiService,
    private accountService: AccountService,
    private paymentAccountApiService: PaymentAccountApiService,
    private organizationApiService: OrganizationApiService,
    private usersService: UsersService,
    private authApiService: AuthApiService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.isKnownUser) {
      this.userInfo$ = this.usersService.getCurrentUserInfo();
      this.kkmsApiService.fetchStatusesCount().subscribe(response => this.totalCount = response);
      this.getBalance();
      this.getOrganization();
      this.getNotifications();
    }
  }

  ngOnDestroy(): void {
    this.subscription.forEach((item: Subscription) => item.unsubscribe());
  }

  logout(): void {
    this.subscription.push(this.authApiService.logout().subscribe(() => {
      this.router.navigate(['landing']);
    }));
  }

  private getOrganization(): void {
    this.subscription.push(this.organizationApiService.fetchOrganization().subscribe(
      (response: IOrganisation) => this.organization = response,
    ));
  }

  private getNotifications(): void {
    this.subscription.push(
      this.accountService.getNotificationsData().subscribe(data => this.notificationsData = data),
    );
  }

  private getBalance(): void {
    this.subscription.push(this.paymentAccountApiService.fetchBalance().subscribe(
      (response: IBalance) => this.balance = response,
      () => {},
    ));
  }

  goNotificationsPage(notificationId: number) {
    this.router.navigate(['/notifications-page'], {
      queryParams: {
        notificationId,
      },
    });
  }

  getFirstLetter(userName: string): string {
    return userName.split('')[0].toLocaleUpperCase();
  }
}
