import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderEnabled } from '../../services/loader.service';
import { showErrorAlert } from '../../../shared/components/alerts/alert.decorator';
import { IBalance, IInvoices, IInvoiceTariffs, IIsmetAccess, IReversRolls } from './payment-account.types';

@Injectable({
  providedIn: 'root',
})

export class PaymentAccountApiService {
  constructor(private httpClient: HttpClient) {}

  @LoaderEnabled()
  registerOrganisation(): Observable<null> {
    return this.httpClient.post<null>('api/ismet/registration', {});
  }

  @showErrorAlert()
  @LoaderEnabled()
  fetchIsmetToken(): Observable<IIsmetAccess> {
    return this.httpClient.get<IIsmetAccess>('/api/ismet/access');
  }

  fetchBalance(): Observable<IBalance> {
    return this.httpClient.get<IBalance>('/api/organisation/balance');
  }

  fetchReverseRoll(params: HttpParams): Observable<IReversRolls> {
    return this.httpClient.get<IReversRolls>('/api/organisation/reverse-roll', {
      params,
    });
  }

  fetchInvoice(id: number, params: HttpParams): Observable<IInvoiceTariffs> {
    return this.httpClient.get<IInvoiceTariffs>(`api/organisation/invoices/${id}`, {
      params,
    });
  }

  fetchInvoices(params: HttpParams): Observable<IInvoices> {
    return this.httpClient.get<IInvoices>('/api/organisation/invoices', {
      params,
    });
  }
}
