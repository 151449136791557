import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taxTextSeparator',
})

export class TextSeparatorPipe implements PipeTransform {
  transform(text: string, separator: string): string[] {
    return text.split(separator).reduce((acc: string[], curr: string) => {
      if (curr !== '') acc.push(curr + separator);
      return acc;
    }, []);
  }
}
