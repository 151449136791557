import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Observable, OperatorFunction, ReplaySubject, filter } from 'rxjs';

export interface IAlert<T extends string | string[] = string | string[]> {
  title?: string;
  message: T;
  messageVariables?: Record<string, string | number>;
  replaceCondition?: RegExp; // Регулярное выражение по которому можем искать ошибку с сервера для замены на message
  type: AlertTypes;
}

type AlertTypes = 'danger' | 'success';

@Injectable({
  providedIn: 'root',
})

export class AlertsService {
  private alertSubject$ = new ReplaySubject<IAlert<string[]>[]>(1);
  private alerts: IAlert<string[]>[] = [];
  private currentUrl = window.location.hash.split('#')[1];
  alerts$: Observable<IAlert<string[]>[]> = this.alertSubject$.asObservable();

  constructor(private router: Router) {
    this.clearAlertsStoreOnRouteChange();
  }

  add(partialAlert: Partial<IAlert>): void {
    const formattedMessage = Array.isArray(partialAlert.message)
      ? partialAlert.message.map(str => str.replace(/\\"/g, '"'))
      : [String(partialAlert.message ?? '').replace(/\\"/g, '"')];
    const alert: IAlert<string[]> = {
      title: partialAlert.title,
      message: formattedMessage,
      messageVariables: partialAlert.messageVariables,
      type: partialAlert.type || 'danger',
    };
    this.alerts = [alert];
    this.alertSubject$.next(this.alerts);
  }

  addSuccessAlert(partialAlert: Partial<IAlert> = {}): void {
    const alert: IAlert = {
      title: partialAlert.title,
      message: partialAlert.message ?? '',
      messageVariables: partialAlert.messageVariables,
      type: 'success',
    };
    this.add(alert);
  }

  clearAlertsStoreOnRouteChange(): void {
    this.router.events
      .pipe(
        filter(event => (event instanceof NavigationEnd)) as OperatorFunction<Event, NavigationEnd>,
      )
      .subscribe((event: NavigationEnd) => {
        const isOtherPage = event.urlAfterRedirects !== this.currentUrl
          && !this.router.getCurrentNavigation()?.extras.state?.isSamePage;
        if (isOtherPage) {
          this.alerts = [];
          this.alertSubject$.next(this.alerts);
        }

        this.currentUrl = event.urlAfterRedirects;
      });
  }

  closeAlert(alertIndex: number): void {
    this.alerts.splice(alertIndex, 1);
    this.alertSubject$.next(this.alerts);
  }
}
