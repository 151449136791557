import { Component, Input } from '@angular/core';
import { SvgIcons } from '@ngneat/svg-icon';

@Component({
  selector: 'tax-icon',
  templateUrl: 'icon.component.html',
  styleUrls: ['icon.component.sass'],
})

export class IconComponent {
  @Input() name?: SvgIcons;
  @Input() width = '24px';
  @Input() height = '24px';
}
