import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputControlComponent } from '../base-input-control/base-input-control.component';

@Component({
  selector: 'tax-input',
  templateUrl: 'input.component.html',
  styleUrls: ['input.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputComponent,
      multi: true,
    },
  ],
})
export class InputComponent extends BaseInputControlComponent {
  @Input() prefix?: string;
}
