import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TabsComponent } from './tabs.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
  ],
  exports: [TabsComponent],
  declarations: [TabsComponent],
  providers: [],
})
export class TabsModule {
}
