import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { combineLatest, defer, Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService, IAlert } from '../../../core/services/alerts.service';
import { AppModule } from '../../../app.module';

export function successAlert(partialAlert: Partial<IAlert<string>>) {
  return function<T>(source: Observable<T>): Observable<T> {
    const alertsService = AppModule.injector.get<AlertsService>(AlertsService);
    const translateService = AppModule.injector.get<TranslateService>(TranslateService);

    return new Observable(subscriber => source
      .pipe(
        mergeMap((result: any) => combineLatest([
          of(result),
          defer(() => (typeof result?.message === 'string'
            ? translateService.get(result?.message)
            : of(undefined))),
        ])),
        tap(([result, translate]: [any, string | undefined]) => {
          const isTranslatableMessage = Boolean(translate) && translate !== result?.message;
          if (isTranslatableMessage && !partialAlert.message) {
            partialAlert.message = result.message;
          }

          partialAlert.type = 'success';
          alertsService.add(partialAlert);
        }),
      ).subscribe({
        next([result, translate]) {
          subscriber.next(result);
        },
        error(error: unknown) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        },
      }));
  };
}

export const showSuccessAlert = (partialAlert: Partial<IAlert<string>>): MethodDecorator => (target: Object, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
  const original = descriptor.value;

  descriptor.value = function (...args: unknown[]) {
    return original.apply(this, arguments)
      .pipe(successAlert(partialAlert));
  };

  return descriptor;
};

export const showErrorAlert = (partialAlert?: Partial<IAlert<string>>): MethodDecorator => (target: Object, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
  const original = descriptor.value;

  descriptor.value = function (...args: unknown[]) {
    const alertsService = AppModule.injector.get<AlertsService>(AlertsService);
    const translateService = AppModule.injector.get<TranslateService>(TranslateService);

    return original.apply(this, arguments)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const alert = getAlert(error);
          const isNonConditionalCustomAlert = partialAlert && !partialAlert.replaceCondition;
          const isSuccessfullyConditionalCustomAlert = partialAlert && partialAlert.replaceCondition?.test(alert.message);

          if (isNonConditionalCustomAlert || isSuccessfullyConditionalCustomAlert) {
            alertsService.add(partialAlert);
            return throwError(() => error);
          }

          defer(() => (alert.message
            ? translateService.get(alert.message)
            : of(undefined)))
            .pipe(first())
            .subscribe((translate) => {
              const isNonTranslatableError = alert.message === translate;
              const isEnglishOrEmptyText = alert.message
                ? new RegExp('[a-z]', 'i').test(alert.message.charAt(0))
                : true;
              const isHtmlError = alert.message.trim().startsWith('<!DOCTYPE html>') || alert.message.trim().startsWith('<html>');
              if (isNonTranslatableError && (isEnglishOrEmptyText || isHtmlError)) {
                alertsService.add({
                  message: 'alert.danger.common.text',
                  title: 'alert.danger.common.title',
                });
              } else {
                alertsService.add(alert);
              }
            });

          return throwError(() => error);
        }),
      );
  };

  return descriptor;
};

function getAlert(error: HttpErrorResponse): Partial<IAlert> & Pick<IAlert<string>, 'message'> {
  const isBp400Error = Boolean(error.error?.requested || error.error?.errorCode);
  if (isBp400Error) {
    return {
      message: handleNonTranslatableErrors(error.error.error || error.error?.errorCode),
    };
  }

  const isHttpResponse400Error = Boolean(error.error?.message);
  if (isHttpResponse400Error) {
    return {
      message: handleNonTranslatableErrors(error.error.message),
    };
  }

  const isOldFormatError = Boolean(error.error);
  if (isOldFormatError) {
    return {
      message: handleNonTranslatableErrors(error.error),
    };
  }

  return {
    message: 'alert.danger.common.text',
    title: 'alert.danger.common.title',
  };
}

function handleNonTranslatableErrors(message: string): string {
  /**
   * isCanNotStartProcessError - Ошибки типа "Cannot start process" и "Cannot start process: kkm.registration.status.not.allowed.error"
   */
  const isCanNotStartProcessError = message.startsWith('Cannot start process');
  if (isCanNotStartProcessError) {
    return message.split('Cannot start process:')[1].trim() || 'cannot.start.process';
  }

  const isNoActiveProcessError = message.startsWith('No active process:');
  if (isNoActiveProcessError) {
    return 'server.error.no.active.process';
  }

  const isUnknownError = ['Error while running process', 'Business error while running process'].includes(message);
  if (isUnknownError) {
    return 'cannot.start.process';
  }

  return message;
}
