import { Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from '../../../../core/modal/modal.service';
import { GroupOfModules } from '../../../../system/groups/group-page/group.model';
import { Selected } from '../../../selected.pipe';
import { UserPermissionsService } from '../../../../system/users/user-permissions.service';
import { ModulesPermissionsModel, Permission } from '../../../../core/modules/admin/admin.types';
import { AdminApiService } from '../../../../core/modules/admin/admin-api.service';

@UntilDestroy()
@Component({
  selector: 'tax-modules-modal',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.sass'],

})

export class ModuleModalComponent {
  @Input() selectedPermissions: Permission[] = [];

  groupsOfPermissions: GroupOfModules[] = [
    {
      name: 'module.permission.agent', permissions: ['agent.view', 'agent.clients'], modules: [],
    },
    {
      name: 'module.permission.userCabinet', permissions: ['default.editOrgData', 'billing.integration'], modules: [],
    },
    {
      name: 'module.permission.reports', permissions: ['default.getReports', 'agent.reports'], modules: [],
    },
    {
      name: 'module.permission.kkms', permissions: ['default.viewKkms', 'default.editKkms', 'default.viewMonitoringFilter'], modules: [],
    },
    {
      name: 'module.permission.users', permissions: ['default.viewUsers', 'default.editUsers'], modules: [],
    },
    {
      name: 'module.permission.cto', permissions: ['cto.integration'], modules: [],
    },
    {
      name: 'module.permission.other', permissions: ['default.editContract', 'admin.support', 'user.roam', 'admin.consult'], modules: [],
    },
  ];

  isSelectAllChecked = false;

  constructor(
    private adminApiService: AdminApiService,
    private modalService: ModalService,
    private userPermissionsService: UserPermissionsService,
  ) {}

  ngOnInit(): void {
    this.setModules();
  }

  saveChanges(): void {
    this.modalService.close(this.allSelectedPermissions);
  }

  groupsTrackBy(index: number, item: GroupOfModules): string {
    return item.name;
  }

  selectModule(module: ModulesPermissionsModel & Selected): void {
    module.selected = !module.selected;
    this.isSelectAllChecked = this.getSelectAllState(this.groupsOfPermissions);
  }

  selectAll(): void {
    this.groupsOfPermissions.forEach((groupOfPermissions) => {
      groupOfPermissions.modules.forEach((module) => {
        module.selected = this.isSelectAllChecked;
      });
    });
  }

  permissionsTrackBy(index: number, item: (ModulesPermissionsModel & Selected)): string {
    return item.description;
  }

  closeModal(): void {
    this.modalService.close();
  }

  private get allSelectedPermissions(): Permission[] {
    // На UI, в модальном окне отображаются и управляются не все права. Например, нельзя изменить в этом окне право retail.*
    // Поэтому отмеченные в модалке права добавляем к списку нередактируемых прав.
    const nonEditableSelectedPermissions = this.selectedPermissions.filter(
      selectedPermission => !this.userPermissionsService.getDisplayedPermissions().includes(selectedPermission),
    );
    const editableSelectedPermission: Permission[] = [];

    this.groupsOfPermissions.forEach((group) => {
      group.modules.forEach((module) => {
        if (module.selected) {
          editableSelectedPermission.push(module.permissions);
        }
      });
    });

    const isAdmin = editableSelectedPermission.includes('admin.support') && editableSelectedPermission.includes('admin.consult');
    if (isAdmin) {
      nonEditableSelectedPermissions.push('admin.admin');
    }

    return nonEditableSelectedPermissions.concat(editableSelectedPermission);
  }

  private setModules(): void {
    this.adminApiService.fetchModules()
      .pipe(untilDestroyed(this))
      .subscribe((modules) => {
        this.groupsOfPermissions.forEach((groupOfPermissions) => {
          groupOfPermissions.modules = modules
            .filter(module => groupOfPermissions.permissions.some(permission => permission === module.permissions))
            .map(module => ({
              ...module,
              selected: this.selectedPermissions.some(selectedModule => module.permissions.includes(selectedModule)),
            }));
        });
        this.isSelectAllChecked = this.getSelectAllState(this.groupsOfPermissions);
      });
  }

  private getSelectAllState(groupsOfPermissions: GroupOfModules[]): boolean {
    return groupsOfPermissions.every(groupOfPermissions => groupOfPermissions.modules.every(module => module.selected === true));
  }
}
