import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UtilsService } from '../../../core/services/utils.service';

interface ListItems extends Array<any> {
  type?: string;
}

@Component({
  selector: 'tax-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.sass'],
})

export class DropDownListComponent implements OnChanges {
  @Input() data: ListItems = [];
  @Input() currentItem: any;
  @Output() submittedData: EventEmitter<any> = new EventEmitter<any>();
  isVisible: boolean = false;
  selectedItem?: any;
  selectedItemText: string = '';

  constructor(public utilService: UtilsService) {}

  ngOnChanges(): void {
    /**
     * При переключении языка интерфейса приходит новый список элементов на новом языке.
     * Поэтому необходимо перевести selectedItem на другой язык;
     */
    this.updateSelectedItem();
  }

  toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  selectItem(item: any): void {
    this.toggleVisibility();
    if (this.data.type === 'bank') {
      this.selectedItem = {
        id: item.bic, name: item.name,
      };
    } else {
      this.selectedItem = {
        id: item.id, name: item.name,
      };
    }
    this.setSelectedItemText();

    this.submittedData.emit(this.selectedItem);
  }

  private setSelectedItemText(): void {
    if (!this.selectedItem) {
      return;
    }

    this.selectedItemText = this.data.type === 'bank'
      ? (`${this.selectedItem.bic} - ${this.selectedItem.name}`)
      : this.selectedItem.name;
  }

  private updateSelectedItem(): void {
    if (!this.currentItem && !this.selectedItem) {
      return;
    }

    if (this.data.length) {
      if (this.data.type === 'bank') {
        const currentItemBic = this.currentItem || this.selectedItem.bic;
        this.selectedItem = this.data.find(element => currentItemBic === element.bic);
      } else {
        const currentItemId = this.currentItem || this.selectedItem.id;
        this.selectedItem = this.data.find(element => currentItemId == element.id);
      }

      this.setSelectedItemText();
    }
  }
}
