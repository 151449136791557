import { Component, Input, OnInit, TrackByFunction } from '@angular/core';
import { UtilsService } from '../../../../core/services/utils.service';
import { ModalService } from '../../../../core/modal/modal.service';
import { Selected } from '../../../selected.pipe';

export interface ViewedPropertiesMap {
  description?: string;
  id?: number;
  title?: string;
}

export interface ListModalOutput<T> {
  items: T[];
  isFullAccess: boolean;
}

interface AnyItem extends Selected, ViewedPropertiesMap {
  [key: string]: any;
}

@Component({
  selector: 'tax-list-modal',
  templateUrl: './list-modal.component.html',
  styleUrls: ['./list-modal.component.sass'],
})

export class ListModalComponent implements OnInit {
  @Input() isFullAccess = false;
  @Input() type?: 'users' | 'retailPlaces' | 'groups';
  @Input() private set items(items: AnyItem[]) {
    this._items = this.utilsService.cloneDeep(items);
  }

  @Input() propertiesMap?: ViewedPropertiesMap;

  readonly trackById: TrackByFunction<AnyItem> = this.utilsService.trackById;

  _items: AnyItem[] = [];
  searchText = '';

  constructor(
    public modalService: ModalService,
    public utilsService: UtilsService,
  ) {}

  ngOnInit(): void {
    this.setIsSelectAllChecked();
  }

  saveChanges(): void {
    const outputData = this.isRetailPlace
      ? {
        items: this._items, isFullAccess: this.isFullAccess,
      }
      : this._items;

    this.modalService.close(outputData);
  }

  selectItem(item: AnyItem): void {
    item.selected = !item.selected;
    this.isFullAccess = this.isAllItemsSelected();
  }

  toggleItemsSelectedState(): void {
    this._items.forEach((item) => {
      item.selected = this.isFullAccess;
    });
  }

  private get isRetailPlace(): boolean {
    return this.type === 'retailPlaces';
  }

  private isAllItemsSelected(): boolean {
    return this._items.every(item => item.selected);
  }

  private setIsSelectAllChecked(): void {
    if (this.isFullAccess) {
      this.toggleItemsSelectedState();
    } else {
      this.isFullAccess = this.isAllItemsSelected();
    }
  }
}
