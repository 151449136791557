import {
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PreloaderComponent } from './preloader.component';

@Directive({
  selector: '[taxPreloader]',
})
export class PreloaderDirective implements OnChanges {
  @Input('taxPreloader') isLoading = true;
  @Input('taxPreloaderHeight') height?: string;

  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      this.vcr.clear();

      if (changes.isLoading.currentValue) {
        const componentRef: ComponentRef<any> = this.vcr.createComponent(PreloaderComponent);
        Object.assign(componentRef.instance, {
          height: this.height,
        });
        componentRef.changeDetectorRef.detectChanges();
      } else {
        this.vcr.createEmbeddedView(this.tpl);
      }
    }
  }
}
