import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'tax-preloader',
  templateUrl: 'preloader.component.html',
  styleUrls: ['preloader.component.sass'],
})

export class PreloaderComponent implements OnInit {
  height: string = '';

  constructor(
    private host: ElementRef<HTMLElement>,
  ) {
  }

  ngOnInit(): void {
    const host = this.host.nativeElement;

    if (this.height) {
      host.style.setProperty('height', this.height);
    }
  }
}
