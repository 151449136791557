import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DEFAULT_OPTIONS } from './isElementVisible.config';

@Directive({
  selector: '[taxIsElementVisible]',
})
export class IsElementVisibleDirective implements OnInit, OnDestroy {
  @Input() options: IntersectionObserverInit = DEFAULT_OPTIONS;
  @Output() visibilityChanged = new EventEmitter<boolean>();
  private observer!: IntersectionObserver;
  constructor(
    private el: ElementRef<HTMLElement>,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((e) => {
          this.visibilityChanged.emit(e.isIntersecting);
        });
      }, this.options);
      this.observer.observe(this.el.nativeElement);
    });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
