import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, OperatorFunction, throwError, catchError, map } from 'rxjs';
import { AlertsService } from '../../core/services/alerts.service';
import { UtilsService } from '../../core/services/utils.service';
import { showErrorAlert } from '../../shared/components/alerts/alert.decorator';
import { SmsCodeSendResponse } from '../../core/modules/auth/auth.types';
import { UsersApiService } from '../../core/modules/users/users-api.service';
import { AuthorizedUserInfo } from './users-page/users-page.types';
import { BlockUserBody, SaveUserBody, SessionUser, User } from '../../core/modules/users/users.types';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private alertsService: AlertsService,
    private usersApiService: UsersApiService,
    private utilsService: UtilsService,
  ) {}

  @showErrorAlert()
  editUser(user: SaveUserBody): Observable<void> {
    return this.usersApiService.editUser(user);
  }

  getCurrentUserInfo(): Observable<AuthorizedUserInfo> {
    return this.usersApiService.fetchCurrentUserInfo()
      .pipe(
        map((userInfo: SessionUser) => {
          (userInfo as AuthorizedUserInfo).uiPermissions = userInfo.permissions.reduce((acc, item) => {
            // @ts-ignore
            acc[item.replace('default.', '')] = true;
            return acc;
          }, {});
          return userInfo;
        }) as OperatorFunction<SessionUser, AuthorizedUserInfo>,
        catchError((error: HttpErrorResponse) => {
          this.alertsService.add({
            message: error.error.error,
          });
          return throwError(error);
        }),
      );
  }

  getUsers(searchCriteria: string = '', retailPlaceId: number = 0, groupId: number = 0): Observable<User[]> {
    const params = this.utilsService.getHttpParams({
      searchCriteria: searchCriteria ?? '',
      retailPlaceId,
      groupId,
    });
    return this.usersApiService.fetchUsers(params);
  }

  getUser(id: number): Observable<User> {
    return this.usersApiService.fetchUser(id)
      .pipe(
        map((user) => {
          if (user.phone) {
            user.phone = this.utilsService.normalizePhone(user.phone);
          }
          return user;
        }),
      );
  }

  blockUser(name: string): Observable<BlockUserBody> {
    return this.usersApiService.blockUser(name);
  }

  changeUserPhone(newPhone: string, code: string, userId: number): Observable<SmsCodeSendResponse | void> {
    return this.usersApiService.changeUserPhone(newPhone, code, userId);
  }

  sendSmsCodeToAuthorizedUser(): Observable<string> {
    return this.usersApiService.sendSmsCodeToAuthorizedUser();
  }
}
