import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AlertsService, IAlert } from '../../../core/services/alerts.service';

@UntilDestroy()
@Component({
  selector: 'tax-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AlertsComponent implements OnInit {
  alerts: IAlert<string[]>[] = [];

  constructor(
    private alertsService: AlertsService,
    private changeDetectorRef: ChangeDetectorRef,
    private viewportScroller: ViewportScroller,
  ) {}

  ngOnInit(): void {
    this.alertsService.alerts$
      .pipe(untilDestroyed(this))
      .subscribe((alerts) => {
        this.alerts = alerts;
        this.changeDetectorRef.markForCheck();
        this.viewportScroller.scrollToPosition([0, 0]);
      });
  }

  close(alertIndex: number): void {
    this.alertsService.closeAlert(alertIndex);
  }
}
