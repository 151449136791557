<div class="tab-list" data-qa="tabs">
  <div
    *ngFor="let tab of tabs"
    data-qa="tab-item tab-text"
    class="tab"
    routerLinkActive="active"
    [routerLink]="tab.url"
  >
    {{ tab.name | translate }}
  </div>
</div>
