import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Settings } from './settings.types';

@Injectable({
  providedIn: 'root',
})

export class SettingsApiService {
  constructor(private httpClient: HttpClient) {}

  fetchSettings(): Observable<Settings> {
    return this.httpClient.get<Settings>('/api/taxpayer/settings/ui');
  }
}
