import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderEnabled } from '../../services/loader.service';
import { BpService } from '../../services/bp.service';
import { showErrorAlert } from '../../../shared/components/alerts/alert.decorator';
import {
  CityItemModel,
  HouseItemModel,
  IRetailPlace,
  RegionItemModel,
  RetailPlace,
  StreetItemModel,
} from './retail-paces.types';
import { Base64String } from '../../../shared/services/signature-nca-common-utils.service';

@Injectable({
  providedIn: 'root',
})

export class RetailPlacesApiService {
  constructor(
    private httpClient: HttpClient,
    private bpService: BpService,
  ) {}

  @LoaderEnabled()
  fetchRetailPlace(id: number | string): Observable<IRetailPlace> {
    return this.httpClient.get<IRetailPlace>(`/api/kkms/retail-place/${id}`);
  }

  @LoaderEnabled()
  setNewRetailPlaceTitle(id: number, params: HttpParams): Observable<any> {
    return this.httpClient.post(`/api/kkms/retail-place/${id}`, {}, {
      params,
    });
  }

  @showErrorAlert()
  @LoaderEnabled()
  deleteRetailPlace(id: number): Observable<any> {
    return this.httpClient.delete(`/api/kkms/retail-place/${id}`);
  }

  fetchRetailPlaces(): Observable<RetailPlace[]> {
    return this.httpClient.get<RetailPlace[]>('api/kkms/retail-places');
  }

  @LoaderEnabled()
  fetchRegion(params: HttpParams): Observable<RegionItemModel[]> {
    return this.httpClient.get<RegionItemModel[]>('/api/regions', {
      params,
    });
  }

  @LoaderEnabled()
  fetchToponyms(areaId: string, params: HttpParams): Observable<CityItemModel[]> {
    return this.httpClient.get<CityItemModel[]>(
      `/api/ats/${areaId}/toponyms`,
      {
        params,
      },
    );
  }

  // Получить ссылку на заявление на подключение vpn в pdf
  fetchVpnConnectDocumentLink(pid?: string): string {
    const processId = pid || this.bpService.getProcessIds().pid;
    return `/api/process/pid/${processId}/vpn-connect-application`;
  }

  // Получить заявление на подключение к vpn в base64
  fetchVpnConnectDocumentBase64(pid?: string): Observable<Base64String> {
    const processId = pid || this.bpService.getProcessIds().pid;
    return this.httpClient.get(`/api/process/pid/${processId}/vpn-connect-application/base64`, {
      responseType: 'text',
    });
  }

  fetchToponym(localityId: string): Observable<any> {
    return this.httpClient.get(`/api/ats/${localityId}`);
  }

  @LoaderEnabled()
  fetchGeonims(localityId: string, params: HttpParams): Observable<StreetItemModel[]> {
    return this.httpClient.get<StreetItemModel[]>(`/api/ats/${localityId}/geonims`, {
      params,
    });
  }

  @LoaderEnabled()
  fetchBuildings(geonimId: string, params: HttpParams): Observable<HouseItemModel[]> {
    return this.httpClient.get<HouseItemModel[]>(`/api/geonims/${geonimId}/buildings`, {
      params,
    });
  }
}
