<div class="wrapper">
  <div class="content">
    <div class="logo_text" [routerLink]="isKnownUser ? 'kkms' : '/landing'">
      <span>ОФД</span>
      <img
        src="../../../../assets/img/logo-217-x-36-color.png"
        srcset="
          ../../../../assets/img/logo-217-x-36-color@2x.png 2x,
          ../../../assets/img/logo-217-x-36-color@3x.png    3x
        "
        alt="logo_img"
      />
    </div>

    <div class="header_right main_info" *ngIf="userInfo$ | async as userInfo">
      <button class="first-level-menu-item" routerLink="kkms" routerLinkActive="active">
        <svg-icon key="kkt" class="kkt-icon"></svg-icon>
        <span
          ><span class="grey-text">{{ totalCount?.totalCount }}</span
          >/{{ totalCount?.activatedCount }}</span
        >
      </button>
      <button
        *ngIf="balance"
        class="first-level-menu-item"
        routerLink="account"
        routerLinkActive="active"
      >
        <svg-icon key="coins" class="coins-icon"></svg-icon>
        <span>{{ balance.amount | kzCurrency }}</span>
      </button>

      <button class="first-level-menu-item notifications-button"
              [matMenuTriggerFor]="infoBell"
              #bellBox="matMenuTrigger"
              [class.active-button]="bellBox.menuOpen">
        <div class="height-box">
          <div class="notification-one" *ngIf="notificationsData.unreadCount <= 1">
            <svg-icon key="notifications-none" [class.bell-icon]="notificationsData.unreadCount === 1"></svg-icon>
            <span class="span-indicator" *ngIf="notificationsData.unreadCount === 1"></span>
          </div>
          <div class="notification-box" *ngIf="notificationsData.unreadCount > 1" >
            {{ notificationsData.unreadCount }}
            <div class="icon-box">
              <svg-icon key="notifications-none" class="bell-icon"></svg-icon>
              <span class="span-indicator"></span>
            </div>
          </div>
        </div>
      </button>
      <mat-menu #infoBell="matMenu" yPosition="above">
        <div class="notification-items-box" *ngIf="notificationsData.notifications.length">
          <div class="items-box">
            <div class="item" [class.cursor-pointer]="n.notificationType === 'BROADCAST_NOTIFICATION'"
                 (click)="!n.isRead && n.notificationType === 'BROADCAST_NOTIFICATION' ? goNotificationsPage(n.id) : null"
                 *ngFor="let n of notificationsData.notifications">
              <div class="inside">
                <div class="title">
                  <span [class.no-read]="!n.isRead"></span>
                  <div class="text">{{n.shortTitle}}</div>
                </div>
                <div class="date">{{n.dateTime | date: 'dd.MM.yy'}}</div>
              </div>
            </div>
          </div>
          <div class="btn-box">
            <svg-icon key="doc-grey"></svg-icon>
            <a routerLink="/notifications-page" translate>notifications.to.list</a>
            <div class="notification-box">{{ notificationsData.unreadCount }}</div>
          </div>
        </div>
      </mat-menu>


      <button
        class="first-level-menu-item user-menu-button"
        [matMenuTriggerFor]="infoMenu"
        #info="matMenuTrigger"
        [class.opened]="info.menuOpen"
      >
        <div class="ava">
          <span *ngIf="userInfo?.name">{{ getFirstLetter($any(userInfo?.name)) }}</span>
          <span *ngIf="!userInfo.name">?</span>
        </div>
      </button>
      <mat-menu #infoMenu="matMenu" yPosition="above">
        <div class="user-menu">
          <div
            class="menu-item menu-inner-block"
            mat-menu-item
            [routerLink]="['users/' + userInfo.id]"
          >
            <svg-icon key="users"></svg-icon>
            <span>{{ userInfo?.name }}</span>
          </div>
          <div class="menu-item" mat-menu-item routerLink="organization">
            <div class="menu-inner-block">
              <svg-icon key="organization"></svg-icon>
              <span>{{ organization?.title }}</span>
            </div>
            <div class="grey-text">
              <span translate>organization.iin</span>: {{ userInfo?.organisation?.inn }}
            </div>
          </div>
          <hr class="greyline" />
          <div class="menu-item menu-inner-block" mat-menu-item (click)="logout()">
            <svg-icon key="exit"></svg-icon>
            <span translate>menu.item.exit</span>
          </div>
        </div>
      </mat-menu>
      <tax-language-switcher></tax-language-switcher>
      <button class="first-level-menu-item" routerLink="help" routerLinkActive="active">
        <svg-icon key="help" class="help-icon"></svg-icon>
      </button>
    </div>

    <div class="header_right" *ngIf="!isKnownUser">
      <span class="first-level-menu-item">8 800 080-00-51</span>
      <tax-language-switcher></tax-language-switcher>
    </div>
  </div>
</div>
