import { BrowserModule } from '@angular/platform-browser';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import localeRUS from '@angular/common/locales/ru-KZ';
import localeKz from '@angular/common/locales/kk';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SystemModule } from './system/system.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './core/layout/layout.module';
import { CoreModule } from './core/core.module';
import { IconsModule } from './shared/icon/icons.module';
import { IsmetInterceptor } from './core/ismet-interceptor';
import { ModalModule } from './core/modal/modal.module';
import { ErrorsInterceptor } from './core/errors-interceptor';
import { MetricsModule } from './shared/metrics/metrics.module';
import { provideEnvironmentNgxMask } from 'ngx-mask';

registerLocaleData(localeRUS);
registerLocaleData(localeKz);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    TranslateModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    SystemModule,
    NoopAnimationsModule,
    MetricsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    LayoutModule,
    MatMomentDateModule,
    IconsModule,
    ModalModule,
  ],
  providers: [
    CookieService,
    TranslateStore,
    {
      provide: LOCALE_ID, useValue: 'ru-kz',
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'ru',
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: IsmetInterceptor, multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true,
    },
    {
      provide: Window, useValue: window,
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static injector: Injector;

  constructor(
    private injector: Injector,
    private translate: TranslateService,
  ) {
    translate.setDefaultLang('ru');
    AppModule.injector = injector;
  }
}
