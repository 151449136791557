import { Component } from '@angular/core';

@Component({
  selector: 'tax-modal-footer',
  templateUrl: 'modal-footer.component.html',
  styleUrls: ['modal-footer.component.sass'],
})

export class ModalFooterComponent {
}
