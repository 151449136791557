import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()

export class DatepickerDateValidationService {
  private isValidDate$ = new BehaviorSubject<boolean>(true);

  getIsValidDate(): Observable<boolean> {
    return this.isValidDate$.asObservable();
  }

  setIsValidDate(value: boolean): void {
    this.isValidDate$.next(value);
  }
}
