import { Component, forwardRef, Input, OnInit, TemplateRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tax-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['../form-controls.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})

export class CheckboxComponent implements OnInit, ControlValueAccessor {
  // w3c input attributes

  // w3c global attributes
  @Input() id = String(Math.floor(Math.random() * Date.now()));

  // component's inputs
  @Input() labelText?: string;
  @Input() labelTemplate: TemplateRef<any> | null = null;

  checkboxControl: UntypedFormControl = new UntypedFormControl('');

  constructor() {
  }

  ngOnInit() {
    this.checkboxControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => {
        this.onChange(value);
      });
  }

  onChange: any = () => {};
  registerOnChange(fn: any): void { this.onChange = fn; }
  onTouch: any = () => {};
  registerOnTouched(fn: any): void { this.onTouch = fn; }
  writeValue(value: any): void {
    if (!value) { return; }

    this.checkboxControl.setValue(value);
  }
}
