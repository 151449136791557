import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SystemRoutingModule } from './system-routing.module';
import { LayoutComponent } from './layout.component';
import { SharedModule } from '../shared/shared.module';
import { AuthInterceptor } from '../core/services/auth-interceptor.service';
import { NewPasswordComponent } from './new-password/new-password.component';
import { LayoutModule } from '../core/layout/layout.module';
import { NotificationsPageComponent } from './notifications-page/notifications-page.component';
import {DatepickerModule} from "../shared/datepicker/datepicker.module";

@NgModule({
  declarations: [
    LayoutComponent,
    NewPasswordComponent,
    NotificationsPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SystemRoutingModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    LayoutModule,
    DatepickerModule,
    MatTooltipModule,
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})

export class SystemModule {}
