import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  HostListener,
  Input,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { PageHeaderDropdownItem } from './page-header.model';

@Component({
  selector: 'tax-page-header',
  templateUrl: 'page-header.component.html',
  styleUrls: ['page-header.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PageHeaderComponent {
  @Input() backButtonUrl?: string;
  @Input() set dropdownItems(dropdownItems: PageHeaderDropdownItem[] | null) {
    this.visibleDropdownItems = dropdownItems
      ?.filter(item => (item.visibilityCondition === undefined ? true : item.visibilityCondition))
      || [];
  }

  @Input() rightSide?: TemplateRef<unknown>;
  @Input() showBackButton = false;
  @Input() titleText?: string;
  @Input() titleAdditionalInfo?: TemplateRef<unknown>;

  // @ts-ignore
  @ViewChild('dropdown') dropdownElement: ElementRef;

  isDropdownVisible: boolean = false;
  visibleDropdownItems: PageHeaderDropdownItem[] = [];

  constructor(
    private location: Location,
    private router: Router,
  ) {
  }

  @HostListener('document:click', ['$event']) closeDropdown(event: MouseEvent): void {
    const isClickOutsideDropdown = !event.composedPath()
      ?.some(element => element === this.dropdownElement?.nativeElement);
    if (isClickOutsideDropdown) {
      this.isDropdownVisible = false;
    }
  }

  dropdownAction(dropdownItem: PageHeaderDropdownItem): void {
    this.isDropdownVisible = false;
    dropdownItem.action();
  }

  goBack(): void {
    this.backButtonUrl
      ? this.router.navigate([this.backButtonUrl])
      : this.location.back();
  }

  trackByTitle: TrackByFunction<PageHeaderDropdownItem> = (index: number, dropdownItem: PageHeaderDropdownItem) => dropdownItem.title;
}
