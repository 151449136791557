import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tax-system',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
