import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { FormModule } from '../components/form-controls/form.module';
import { SharedModule } from '../shared.module';
import { DatepickerInputDirective } from './datepicker/datepicker-input.directive';

// eslint-disable-next-line @typescript-eslint/typedef
const exportedComponents = [
  DatepickerComponent,
];

const exportedDirectives = [
  DatepickerInputDirective,
];

@NgModule({
  imports: [
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    FormModule,
    FormsModule,
    CommonModule,
    SharedModule,
    NzToolTipModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  exports: [
    ...exportedComponents,
    ...exportedDirectives,
  ],
  declarations: [
    ...exportedComponents,
    ...exportedDirectives,
  ],
  providers: [],
})
export class DatepickerModule {
}
