import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { errorPrefix } from '../../errors-interceptor';
import { LoginBySmsFormValue, SignInPayload, SmsCodeSendResponse, SmsSendNewPhone, UserSmsLogin } from './auth.types';
import { showErrorAlert, showSuccessAlert } from '../../../shared/components/alerts/alert.decorator';
import { LoaderEnabled } from '../../services/loader.service';
import { SessionUser } from '../users/users.types';
import { Http200ErrorResponse } from '../../models/http.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private readonly headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) {}

  @showSuccessAlert({
    message: 'user.password.restore.succeeded',
    title: 'user.password.restore.succeeded.title',
  })
  @showErrorAlert()
  @LoaderEnabled()
  public sendLogin(login: string): Observable<any> {
    return this.httpClient.post('/api/user/restore-password', {
      login,
    });
  }

  @showErrorAlert()
  @LoaderEnabled()
  public singIn(user: SignInPayload): Observable<void | Http200ErrorResponse> {
    return this.httpClient.post<void | Http200ErrorResponse>('/api/user/login', user, this.headers);
  }

  @showErrorAlert({
    message: 'user.password.failed',
  })
  @LoaderEnabled()
  sendNewPassword(password: string | null, params: string): Observable<SessionUser> {
    return this.httpClient.post<SessionUser>(`/api/user/new-password/${params}`, {
      password,
    });
  }

  @LoaderEnabled()
  public loginBySignature(data: string): Observable<any> {
    return this.httpClient.post('/api/user/loginBySignature', {
      signature: data,
    });
  }

  @showErrorAlert()
  loginBySms(loginBySmsPayload: UserSmsLogin): Observable<SmsCodeSendResponse | Http200ErrorResponse> {
    return this.httpClient.post<SmsCodeSendResponse | Http200ErrorResponse>('/api/user/loginBySms', loginBySmsPayload);
  }

  sendPasswordToNewPhone(payload: SmsSendNewPhone): Observable<SmsCodeSendResponse> {
    return this.httpClient.post<SmsCodeSendResponse>('/api/user/sms/send-new-phone', {
      ...payload,
    }, {
      context: errorPrefix('sms.code.send.error'),
    });
  }

  sendPasswordToUserPhone(payload: LoginBySmsFormValue): Observable<SmsCodeSendResponse> {
    return this.httpClient.post<SmsCodeSendResponse>('/api/sms/sendLoginCode', payload, {
      context: errorPrefix('sms.code.send.error'),
    });
  }

  logout(): Observable<unknown> {
    return this.httpClient.post('/api/user/logout', {}, this.headers);
  }

  getAnonymousUser(): Observable<unknown> {
    return this.httpClient.post('/api/user/login-anonymous', {}, this.headers);
  }

  sendEmail(mail: string): Observable<void> {
    return this.httpClient.post<void>('/api/user/sendEmailWithAuth', {
      email: mail,
    });
  }

  validateSecretKey(secretKey: string): Observable<void> {
    return this.httpClient.post<void>('/api/user/checkIsConfirm', {
      confirmIdf: secretKey,
    });
  }

  getConfirm(): Observable<void> {
    return this.httpClient.get<void>('/api/user/confirmEmail');
  }
}
