import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { REGEXP_EXCLUDE_DIGITS } from '../../../../core/services/regexp';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  isPasswordStrength(passwordControl: AbstractControl): ValidationErrors | null {
    const password = passwordControl.value;
    if (!password) {
      return null;
    }

    let errorMessage;

    if (password.match(/\s/)) {
      errorMessage = 'form.error.password.space';
    } else if (password.match(/[а-яёА-ЯЁӘәІіҢңҒғҮүҰұҚқӨөҺһ]/)) {
      errorMessage = 'form.error.password.latin';
    } else if (password.length < 8) {
      errorMessage = 'form.error.password.length';
    } else if (!password.match(/[a-z]/)) {
      errorMessage = 'form.error.password.letter';
    } else if (!password.match(/[A-Z]/)) {
      errorMessage = 'form.error.password.capital';
    } else if (!password.match(/[0-9]/)) {
      errorMessage = 'form.error.password.digital';
    } else if (!password.match(/[@?_*=+!/-]/)) {
      errorMessage = 'form.error.password.special';
    }

    return !errorMessage
      ? null
      : {
        errorMessage,
        weakPassword: true,
      };
  }

  isEqual(passwordControl: AbstractControl, anotherPasswordControl: AbstractControl): ValidationErrors | null {
    if (!passwordControl.value && !anotherPasswordControl?.value) {
      return null;
    }

    return passwordControl?.value === anotherPasswordControl?.value
      ? null
      : {
        isEqual: false,
        errorMessage: 'form.error.password.confirm',
      };
  }

  isEmailCorrect(passwordControl: AbstractControl): ValidationErrors | null {
    if (!passwordControl.value) {
      return null;
    }

    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z0-9_\-\.])+$/;

    return reg.test(passwordControl.value)
      ? null
      : {
        isEqual: false,
        errorMessage: 'user.form.incorrect.email',
      };
  }

  kzPhoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const phoneDigits = control.value?.replace(REGEXP_EXCLUDE_DIGITS, '') || '';
      const isValid = phoneDigits.length === 0 || phoneDigits.startsWith('77');
      return isValid ? null : {
        invalidPhone: {
          valid: false,
        },
        errorMessage: 'common.error.phone.kz.only',
      };
    };
  }

  phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const phoneDigits = control.value?.replace(REGEXP_EXCLUDE_DIGITS, '') || '';
      const isValid = phoneDigits.length === 0 || phoneDigits.length >= 11;
      return isValid ? null : {
        invalidPhone: {
          valid: false,
        },
        errorMessage: 'organisation.phone.invalid',
      };
    };
  }

  samePhoneValidator(phone?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!phone) return null;

      const isValid = control.value?.replace(REGEXP_EXCLUDE_DIGITS, '') !== phone?.replace(REGEXP_EXCLUDE_DIGITS, '');
      return isValid ? null : {
        invalidPhone: {
          valid: false,
        },
        errorMessage: 'sms.verification.phone.change.required',
      };
    };
  }

  validateInn(control: AbstractControl<string>): ValidationErrors | null {
    const isInnValid = !control.value || control.value.length === 12;
    return isInnValid
      ? null
      : {
        errorMessage: 'form.error.inn.incorrect',
      };
  }
}
