import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment/moment';
import { DatepickerDateValidationService } from './services/datepicker-date-validation.service';

@Directive({
  selector: '[matDatepicker][taxDatepickerInput]',
})
export class DatepickerInputDirective {
  @Input() startDate: Moment | null = null;
  private sanitizedValue = '';

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private datepickerDateValidationService: DatepickerDateValidationService,
  ) {}

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const currentValue: string = this.el.nativeElement.value;
    this.sanitizedValue = currentValue.replace(/[^0-9.]/g, '');
    if (currentValue !== this.sanitizedValue) {
      event.stopPropagation();
    }
    this.validateDate();
    this.el.nativeElement.value = this.sanitizedValue;
  }

  private validateDate(): void {
    const date: Moment = moment(this.sanitizedValue, 'DD.MM.YYYY');
    if (this.sanitizedValue !== '') {
      this.datepickerDateValidationService.setIsValidDate(date.isValid());
    } else {
      this.datepickerDateValidationService.setIsValidDate(true);
    }
  }
}
