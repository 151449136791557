import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable, filter } from 'rxjs';
import { ModalComponent } from './modal/modal.component';
import { ModalData, ComponentInputs, ModalSettings, ModalWidth, ModalOutput, ModalComponentType } from './modal.model';
import { isItemExist } from '../../shared/file.utils';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  dialogRef?: MatDialogRef<any>;
  private isOpenedModal = false;

  constructor(private dialog: MatDialog) {}

  close<T extends ModalComponentType>(data?: ModalOutput<T>, callback: (() => void) | undefined = undefined): void {
    this.isOpenedModal = false;
    this.dialogRef?.close(data || null);
    this.dialogRef = undefined;

    if (callback) {
      callback();
    }
  }

  openOld<R = any>(component:any, componentInputs: ComponentInputs = {}, settings: ModalSettings = {}): Observable<R> {
    this.isOpenedModal = true;
    const modalComponentData: ModalData = {
      component,
      componentInputs,
      settings,
    };

    this.dialogRef = this.dialog.open(ModalComponent, {
      data: modalComponentData,
      width: settings?.width || ModalWidth.sm,
    });

    return this.dialogRef.afterClosed()
      .pipe(filter(result => result !== null)) as Observable<R>;
  }

  open<T extends ModalComponentType>(component:T, componentInputs: ComponentInputs = {}, settings: ModalSettings = {}): Observable<NonNullable<ModalOutput<T>>> {
    this.isOpenedModal = true;
    const modalComponentData: ModalData = {
      component,
      componentInputs,
      settings,
    };

    this.dialogRef = this.dialog.open(ModalComponent, {
      data: modalComponentData,
      width: settings?.width || ModalWidth.sm,
    });

    return this.dialogRef.afterClosed()
      .pipe(filter(isItemExist));
  }

  getModalVisibility(): boolean {
    return this.isOpenedModal;
  }
}
