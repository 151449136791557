import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalService } from '../../../../core/modal/modal.service';

@Component({
  selector: 'tax-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  // @ts-ignore
  @Input() dialogText: string;
  // @ts-ignore
  @Input() action: () => void;
  // @ts-ignore
  @Input() buttonText: string;
  // @ts-ignore
  @Input() title: string;

  constructor(
    private modalService: ModalService,
  ) {
  }

  apply(): void {
    this.action();
    this.modalService.close();
  }

  close(): void {
    this.modalService.close();
  }
}
