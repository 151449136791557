import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { INotificationFilters, INotificationReadBody, INotificationsData } from './notification.types';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  constructor(private httpClient: HttpClient) {}

  fetchNotifications(): Observable<INotificationsData> {
    return this.httpClient.get<INotificationsData>('/api/notifications/bell');
  }

  fetchFilteredNotifications(body: INotificationFilters): Observable<INotificationsData> {
    return this.httpClient.post<INotificationsData>('/api/notifications/filtered', body);
  }

  setNotificationsReadStatus(body: INotificationReadBody[]): Observable<any> {
    return this.httpClient.post<any>('/api/notifications/read', body);
  }
}
