import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { UtilsService } from '../../core/services/utils.service';

@Component({
  selector: 'tax-access-settings-block',
  templateUrl: 'access-settings-block.component.html',
  styleUrls: ['access-settings-block.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AccessSettingsBlockComponent {
  @Input() title?: string;
  @Input() placeholder?: string;
  @Input() items: unknown[] = [];
  @Input() itemTemplate?: TemplateRef<any>;
  @Input() iterableProperty = 'name';
  @Input() isNeedTranslate = false;

  @Output() openModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public utilService: UtilsService,
  ) {
  }
}
